import { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { loadI18n } from '~/core/i18n';

loadI18n(() => {
	const container = document.getElementById('root');
	if (!container) return;
	const root = createRoot(container);
	const App = lazy(() => import('./App'));
	root.render(<App />);
});

reportWebVitals();
